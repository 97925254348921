import { FC, Fragment } from "react";
import { Settings } from "react-slick";
import { Dialog, Transition } from "@headlessui/react";
import XIcon from "@heroicons/react/solid/XIcon";
import HeartIcon from "@heroicons/react/outline/HeartIcon";
import ChatIcon from "@heroicons/react/outline/ChatIcon";
import { useTranslation } from "next-i18next";

import { MediaObject, SimpleMediaObject } from "@interfaces/instagram";
import ResponsiveSlider from "@components/common/responsive-slider";
import InstagramIcon from "@components/icons/instagram-icon";
import { useStaticData } from "@context/static";

const Image: FC<Pick<MediaObject, "caption" | "media_url">> = ({
  caption,
  media_url,
}) => (
  <div className="mx-auto max-w-[94%] lg:max-w-[92%] xl:max-w-[90%] 2xl:max-w-[88%]">
    <img src={media_url} alt={caption} />
  </div>
);

const Video: FC<Pick<MediaObject, "media_url">> = ({ media_url }) => (
  <div className="w-full">
    <video controls className="w-full">
      <source src={media_url} />
    </video>
  </div>
);

const Carousel: FC<{ data: SimpleMediaObject[] }> = ({ data }) => (
  <div className="pt-2 lg:pt-0">
    <ResponsiveSlider
      breakpoints={{ base: 1 }}
      settings={{
        initialSlide: 0,
        dots: true,
        className: "post-image-carousel",
        // eslint-disable-next-line react/display-name
        appendDots: (dots) => (
          <div>
            <div className="flex items-center justify-center">
              <ul className="bg-black bg-opacity-60 flex rounded-md">{dots}</ul>
            </div>
          </div>
        ),
        // eslint-disable-next-line react/display-name
        customPaging: (i) => (
          <div className="flex justify-center items-center w-full h-full">
            <button aria-label={`slide ${i}`} />
          </div>
        ),
      }}
    >
      {data.map((media) =>
        media.media_type === "IMAGE" ? (
          // eslint-disable-next-line jsx-a11y/alt-text
          <Image {...media} />
        ) : (
          <Video {...media} />
        )
      )}
    </ResponsiveSlider>
  </div>
);

const Text: FC<
  Pick<MediaObject, "caption" | "comments_count" | "like_count" | "permalink">
> = ({ caption, comments_count, like_count, permalink }) => {
  const { socialLinks } = useStaticData();
  const { t } = useTranslation("home");

  const __html = caption
    .replace(
      /\s#\S*/g,
      (text) => ` <span class="font-bold italic">${text}</span>`
    )
    .replace(/\s@\S*/g, (text) => ` <span class="font-bold">${text}</span>`);

  const href = socialLinks.find(
    ({ name }) => name.toLowerCase() === "instagram"
  )?.link;

  return (
    <div className="w-full h-full relative p-4 pt-2">
      <div className="h-4/5">
        <p
          className="line-clamp-4 md:line-clamp-4 lg:line-clamp-12
          xl:line-clamp-16"
          dangerouslySetInnerHTML={{ __html }}
        />
      </div>
      <div className="h-1/5 border-t border-black pt-2">
        <div className="h-10 w-2/3 flex items-center justify-center space-x-1 mx-auto">
          <HeartIcon className="text-black h-2/3" />
          <div className="text-lg h-full flex items-center justify-center">
            {like_count}
          </div>
          <ChatIcon className="text-black h-2/3 scale-x-[-1]" />
          <div className="text-lg h-full flex items-center justify-center">
            {comments_count}
          </div>
        </div>
        <div className="cursor-pointer hover:underline font-bold text-center lg:py-4 pt-4 pb-16">
          <a href={permalink} target="_blank" rel="noreferrer">
            {t("featured.posts.view-insta")}
          </a>
        </div>
        <div className="absolute right-0 bottom-0 p-4 group">
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className="flex flex-row w-full"
          >
            <div className="scale-x-0 group-hover:scale-x-100 transition-all duration-100 origin-right pt-1 font-semibold">
              {t("featured.posts.follow-insta")}
            </div>
            <InstagramIcon className="h-8" />
          </a>
        </div>
      </div>
    </div>
  );
};

const Post: FC<MediaObject> = (props) => (
  <div className="w-full h-full lg:aspect-w-16 lg:aspect-h-9 max-h-[90vh] lg:max-h-full">
    <div className="flex flex-col lg:flex-row items-center justify-between bg-white max-h-full">
      <div className="w-full md:w-11/12 lg:w-2/3 pb-2 lg:pb-0">
        <div className="overflow-hidden">
          {props.media_type === "CAROUSEL_ALBUM" ? (
            <Carousel {...props.children} />
          ) : (
            <Carousel {...{ data: [props] }} />
          )}
        </div>
      </div>
      <div className="w-full lg:w-1/3 h-full border-t lg:border-t-0 lg:border-l border-black">
        <Text {...props} />
      </div>
    </div>
  </div>
);

interface Props {
  show: boolean;
  hide(): void;
  posts: MediaObject[];
  initial: number;
}

const PostsCarousel: FC<Props> = ({ show, hide, posts, initial }) => {
  const settings: Settings = {
    initialSlide: initial,
    className: "image-slider",
  };

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        onClose={() => hide()}
        className="fixed inset-0 z-50 overflow-hidden"
      >
        <Dialog.Overlay className="bg-black/80 fixed inset-0" />
        <Transition.Child
          as={Fragment}
          enter="transition origin-center duration-300 ease-out"
          enterFrom="scale-90 opacity-0"
          enterTo="scale-100 opacity-100"
          leave="transition origin-center duration-100 ease-in"
          leaveFrom="scale-100 opacity-100"
          leaveTo="scale-90 opacity-0"
        >
          <div className="w-full h-full flex justify-center items-center">
            <div className="absolute top-4 md:top-10 right-4 md:right-10 w-10 h-10 z-20">
              <button
                className="w-full h-full focus:outline-none"
                onClick={() => hide()}
              >
                <XIcon className="text-white" />
              </button>
            </div>
            <div className="w-3/4 max-h-[90vh] -translate-y-[10vh]">
              <div className="w-full h-full">
                <ResponsiveSlider {...{ settings }} breakpoints={{ base: 1 }}>
                  {posts.map((post) => (
                    <div key={post.id} className="px-2">
                      <Post {...post} key={post.id} />
                    </div>
                  ))}
                </ResponsiveSlider>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default PostsCarousel;
