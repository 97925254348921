import { FC } from "react";
import { Review } from "@prisma/client";
import { useTranslation } from "next-i18next";

import ReviewCard from "@components/common/review-card";
import Stars from "@components/icons/stars";
import ResponsiveSlider from "@components/common/responsive-slider";

interface Props {
  reviews: Review[];
}

const FeaturedReviews: FC<Props> = ({ reviews }) => {
  const { t } = useTranslation("home");

  return reviews.length > 0 ? (
    <div className="flex flex-row justify-center lg:justify-around flex-wrap px-4 space-y-4 lg:space-y-0 pb-24">
      <div className="text-black flex flex-col items-center justify-evenly">
        <strong className="text-3xl">{3.5}</strong>
        <Stars rating={3.5} scale={125} />
        <h2>
          {t("featured.reviews.title")} <strong>10179</strong>{" "}
          {t("featured.reviews.reviews")}
        </h2>
      </div>
      <div className="w-full lg:w-4/5">
        <ResponsiveSlider
          breakpoints={{
            xxl: 4,
            lg: 2,
            base: 1,
          }}
          settings={{
            className: "reviews-slideshow",
          }}
        >
          {reviews.map((review) => (
            <div key={review.id} className="px-2">
              <ReviewCard {...review} />
            </div>
          ))}
        </ResponsiveSlider>
      </div>
    </div>
  ) : null;
};

export default FeaturedReviews;
