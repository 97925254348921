import StarIcon from "@heroicons/react/solid/StarIcon";
import { FC } from "react";

interface Props {
  colored: boolean;
  scale: 100 | 125 | 150;
}

const Star: FC<Props> = ({ colored, scale }) => (
  <StarIcon
    className={`w-5 ${colored ? "text-black" : "text-gray-300"} transform ${
      scale === 100 ? "scale-100" : scale === 125 ? "scale-125" : "scale-150"
    }`}
  />
);

export default Star;
