import { FC } from "react";

import Star from "./star-icon";

interface Props {
  rating: number;
  scale?: 100 | 125 | 150;
}

const Stars: FC<Props> = ({ rating, scale }) => (
  <div className="flex flex-row justify-between">
    <Star colored={rating >= 1} scale={scale ?? 100} />
    <Star colored={rating >= 2} scale={scale ?? 100} />
    <Star colored={rating >= 3} scale={scale ?? 100} />
    <Star colored={rating >= 4} scale={scale ?? 100} />
    <Star colored={rating >= 5} scale={scale ?? 100} />
  </div>
);

export default Stars;
