import { useState, useEffect } from "react";

interface UseSliderResize {
  (breakpoints: {
    xxl?: number;
    xl?: number;
    lg?: number;
    md?: number;
    sm?: number;
    base: number;
  }): number;
}

const breakpoints = {
  xxl: 1536,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
};

export const useSliderResize: UseSliderResize = ({
  xxl,
  xl,
  lg,
  md,
  sm,
  base,
}) => {
  const [slidesToShow, setSlidesToShow] = useState(base);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      let numSlides;

      if (xxl !== undefined && width >= breakpoints.xxl) {
        numSlides = xxl;
      } else if (xl !== undefined && width >= breakpoints.xl) {
        numSlides = xl;
      } else if (lg !== undefined && width >= breakpoints.lg) {
        numSlides = lg;
      } else if (md !== undefined && width >= breakpoints.md) {
        numSlides = md;
      } else if (sm !== undefined && width >= breakpoints.sm) {
        numSlides = sm;
      } else {
        numSlides = base;
      }

      setSlidesToShow(numSlides);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [xxl, xl, lg, md, sm, base]);

  return slidesToShow;
};
