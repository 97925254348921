import { Supplier } from "@prisma/client";
import { FC } from "react";
import Link from "next/link";

import { getMediaWithSize } from "@utils/get-media";

interface Props {
  suppliers: Supplier[];
}

const FeaturedSuppliers: FC<Props> = ({ suppliers }) =>
  suppliers.length > 0 ? (
    <div className="max-w-screen flex flex-col items-center py-10">
      <h2 className="text-black text-2xl font-bold pb-4">
        <Link href={{ pathname: "/partners" }}>
          <a className="cursor hover:underline">Partneri</a>
        </Link>
      </h2>
      <div className="w-full flex justify-center items-center flex-wrap">
        {suppliers.map((supplier) => (
          <div
            key={supplier.name}
            className="p-3 w-1/3 sm:w-1/4 md:w-1/6 lg:w-[12.5%]"
          >
            <a href={supplier.url} target="_blank" rel="noreferrer">
              <div>
                <img
                  src={getMediaWithSize(supplier.featuredPhoto, "sm")}
                  alt={supplier.name}
                  className="w-full object-cover"
                />
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  ) : null;

export default FeaturedSuppliers;
