import { BlogPost, Order, Product, Review, User } from "@prisma/client";
import { ConditionalKeys } from "type-fest";

import { NonNullableDeep } from "@interfaces/utility";

type DateKeysArray<Type> = Array<ConditionalKeys<NonNullableDeep<Type>, Date>>;

interface Serializer<SerDeType> {
  <InstanceType extends SerDeType>(deserialized: InstanceType): InstanceType;
}

interface Deserializer<SerDeType> {
  <InstanceType extends SerDeType>(serialized: InstanceType): InstanceType;
}

interface GeneratorReturnType<SerDeType> {
  serializer: Serializer<SerDeType>;
  deserializer: Deserializer<SerDeType>;
}

function isDate(value: unknown): value is Date {
  return !!value && typeof value === "object" && value instanceof Date;
}

function isString(value: unknown): value is string {
  return !!value && typeof value === "string";
}

function generateSerDe<SerDeType extends Record<string, unknown>>(
  ...dateKeys: DateKeysArray<SerDeType>
): GeneratorReturnType<SerDeType> {
  const helper = dateKeys as Array<keyof SerDeType>;

  const serializer: Serializer<SerDeType> = (deserialized) =>
    Object.keys(deserialized).reduce((serialized, key) => {
      const value = deserialized[key];
      const toSerialize = isDate(value) && helper.includes(key);

      return {
        ...serialized,
        [key]: toSerialize ? (value.toISOString() as unknown as Date) : value,
      };
    }, {} as typeof deserialized);

  const deserializer: Deserializer<SerDeType> = (serialized) =>
    Object.keys(serialized).reduce((deserialized, key) => {
      const value = serialized[key];
      const toDeserialize = isString(value) && helper.includes(key);

      return {
        ...deserialized,
        [key]: toDeserialize ? new Date(value) : value,
      };
    }, {} as typeof serialized);

  return { serializer, deserializer };
}

export const {
  serializer: serializeBlogPost,
  deserializer: deserializeBlogPost,
} = generateSerDe<BlogPost>("datePosted");

export const {
  serializer: serializeProduct,
  deserializer: deserializeProduct,
} = generateSerDe<Product>("createdAt");

export const { serializer: serializeReview, deserializer: deserializeReview } =
  generateSerDe<Review>("date");

export const { serializer: serializeUser, deserializer: deserializeUser } =
  generateSerDe<User>("updatedAt", "createdAt", "emailVerified");

export const { serializer: serializeOrder, deserializer: deserializeOrder } =
  generateSerDe<Order>("date");
