import HeartIcon from "@heroicons/react/outline/HeartIcon";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, FC, useState } from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { useSession } from "next-auth/client";

import { useWishlistItem } from "@context/wishlist";

const LikeDialog: FC<{ open: boolean; close(): void }> = ({ open, close }) => {
  const { t } = useTranslation("common");

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        className="fixed flex justify-center items-center inset-0 z-30"
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-30"
          leave="ease-in duration-200"
          leaveFrom="opacity-30"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/30" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90"
        >
          <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all bg-white shadow-xl rounded-2xl z-10">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {t("wishlist.logged-in")}
            </Dialog.Title>

            <Link href={{ pathname: "/auth/login" }}>
              <a className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-offset-2 focus-visible:ring-gray-700 bg-green-400 mx-2">
                {t("header.header.login")}
              </a>
            </Link>

            <button
              onClick={close}
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-offset-2 focus-visible:ring-gray-700 bg-red-400 mx-2"
            >
              OK
            </button>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

interface Props {
  productId: number;
  className?: string;
}

const LikeButton: FC<Props> = ({ className, productId }) => {
  const { isInWishlist, toggle } = useWishlistItem(productId);
  const [session] = useSession();
  const [animate, setAnimate] = useState(false);
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);

  return (
    <button
      onClick={(event) => {
        event.preventDefault();

        if (session && session.user) {
          toggle();
          setAnimate(!isInWishlist);
        } else {
          setOpen(true);
        }
      }}
      className={`outline-none focus:outline-none ${className} ${
        isInWishlist ? "text-red-400" : ""
      }`}
    >
      <span className="sr-only">{t("like")}</span>
      <HeartIcon
        className={`w-full hover:fill-current ${
          isInWishlist ? "fill-current" : ""
        } ${animate && isInWishlist && "animate-scale"}`}
        onAnimationEnd={() => setAnimate(false)}
      />
      <LikeDialog {...{ open, close: () => setOpen(false) }} />
    </button>
  );
};

export default LikeButton;
