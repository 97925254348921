import dayjs from "dayjs";
import "dayjs/locale/sr";

export function formatDate(
  date: Date | string,
  formatString: string,
  addDays = 0
): string {
  return dayjs(date).locale("sr").add(addDays, "day").format(formatString);
}
