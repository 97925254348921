import { FC } from "react";
import { Category } from "@prisma/client";
import { useRouter } from "next/router";

import ImageLink from "@components/common/image-link";

interface Props {
  categories: Category[];
}

const Categories: FC<Props> = ({ categories }) => {
  const { locale } = useRouter();

  return (
    <div className="flex flex-wrap">
      {categories.map(({ slug, name, featuredPhoto: img, nameEn }) => (
        <ImageLink
          {...{
            img,
            title: locale === "en" ? nameEn : name,
            href: `/categories/${slug}`,
          }}
          key={slug}
        />
      ))}
    </div>
  );
};

export default Categories;
