import { FC } from "react";
import { Review } from "@prisma/client";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import Stars from "../icons/stars";
import VerifiedIcon from "../icons/verified-icon";

import { formatDate } from "@utils/datetime";

type Props = Review;

const ReviewCard: FC<Props> = ({ rating, date, text, verified, textEn }) => {
  const { locale } = useRouter();
  const { t } = useTranslation("home");

  return (
    <div className="flex flex-col justify-start bg-white w-full px-4 py-3 rounded-md text-black divide-y-2 divide-gray-500 space-y-2 border border-black">
      <div className="flex flex-row items-center space-x-4">
        <Stars rating={rating} />
        <span>{formatDate(date, "DD/MM/YYYY")}</span>
      </div>
      <p className="line-clamp-2 leading-loose pt-2">
        {locale === "en" ? textEn : text}
      </p>
      {!verified ? null : (
        <div className="flex flex-row pt-2 space-x-4">
          <VerifiedIcon size={20} />
          <span>{t("featured.reviews.verified")}</span>
        </div>
      )}
    </div>
  );
};

export default ReviewCard;
