import { FC } from "react";
import { GetStaticProps } from "next";
import {
  Category,
  LandingPageSlide,
  Product,
  Review,
  Supplier,
} from "@prisma/client";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";

import Categories from "@components/home/categories";
import FeaturedProducts from "@components/home/featured-products";
import FeaturedReviews from "@components/home/featured-reviews";
import FeaturedSuppliers from "@components/home/featured-suppliers";
import Slideshow from "@components/home/slideshow";
import Layout from "@components/common/layout";
import prisma, { getStaticData } from "@lib/prisma";
import {
  deserializeProduct,
  deserializeReview,
  serializeProduct,
  serializeReview,
} from "@utils/serialize";
import { revalidate } from "@constants/revalidate";
import { StaticProps } from "@interfaces/page";
import { getPosts } from "@lib/instagram";
import FeaturedPosts from "@components/home/featured-posts";
import { MediaObject } from "@interfaces/instagram";

interface Props extends StaticProps {
  slides: LandingPageSlide[];
  firstRow: Product[];
  secondRow: Product[];
  categories: Category[];
  reviews: Review[];
  suppliers: Supplier[];
  posts: MediaObject[];
}

const HomePage: FC<Props> = ({
  slides,
  firstRow,
  secondRow,
  categories,
  reviews,
  suppliers,
  posts,
}) => {
  const { t } = useTranslation("home");

  return (
    <Layout {...{ title: t("title"), description: t("description") }}>
      <Slideshow {...{ slides }} />
      <FeaturedProducts {...{ products: firstRow.map(deserializeProduct) }} />
      <Categories {...{ categories }} />
      <FeaturedProducts {...{ products: secondRow.map(deserializeProduct) }} />
      {/* <FeaturedBlogPosts
        {...{ blogPosts: blogPosts.map(deserializeBlogPost) }}
      /> */}
      <FeaturedPosts {...{ posts }} />
      <div className="hidden lg:block">
        <FeaturedSuppliers {...{ suppliers }} />
      </div>
      <FeaturedReviews {...{ reviews: reviews.map(deserializeReview) }} />
    </Layout>
  );
};

export default HomePage;

export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => {
  const take = 16;
  const skip = 0; // new Date().getDay() + take;

  const [
    slides,
    firstRow,
    secondRow,
    // blogPosts,
    reviews,
    suppliers,
    staticData,
    // TODO: improve static data loading
  ] = await getStaticData([
    prisma.landingPageSlide.findMany({ orderBy: { id: "asc" } }),
    prisma.product.findMany({
      where: {
        published: true,
        category: {
          isInFirstRow: true,
        },
      },
      take,
      skip,
      orderBy: { createdAt: "desc" },
    }),
    prisma.product.findMany({
      where: {
        published: true,
        category: {
          isInSecondRow: true,
        },
      },
      take,
      skip,
      orderBy: { createdAt: "desc" },
    }),
    // fetchBlogPosts({
    //   where: { published: true },
    //   take: 3,
    //   orderBy: { datePosted: "desc" },
    // }),
    prisma.review.findMany({
      where: { productId: null },
      orderBy: { date: "desc" },
    }),
    prisma.supplier.findMany({ where: { isOnLandingPage: true } }),
  ]);

  const posts = (await getPosts()) || [];

  const translations = await serverSideTranslations(locale as string, [
    "common",
    "home",
  ]);

  return {
    props: {
      categories: staticData.categories,
      slides,
      reviews: reviews.map(serializeReview),
      firstRow: firstRow.map(serializeProduct),
      secondRow: secondRow.map(serializeProduct),
      // blogPosts: blogPosts.map((blogPost) => ({
      //   ...serializeBlogPost(blogPost),
      //   tags: blogPost.tags.map(({ tag }) => tag),
      // })),
      suppliers,
      posts,
      staticData,
      ...translations,
    },
    revalidate,
  };
};
