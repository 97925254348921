import { FC, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { Transition } from "@headlessui/react";
import { useTranslation } from "next-i18next";

import LikeButton from "@components/common/like-button";
import { getMediaSizes, imageLoader } from "@utils/get-media";
import { classNames } from "@utils/class-names";

interface Props {
  photo: string;
  highlightPhoto: string;
  productId: number;
  slug?: string;
  name: string;
  withLikeButton?: boolean;
  withLink?: boolean;
  onDiscount?: boolean;
}

const ProductImage: FC<Props> = ({
  photo,
  onDiscount,
  productId,
  highlightPhoto,
  name,
  slug,
  withLikeButton = true,
  withLink = true,
}) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation("common");
  const hasHighlightPhoto =
    !!highlightPhoto &&
    highlightPhoto.length > 0 &&
    highlightPhoto !== "featured-photo-placeholder.png" &&
    !highlightPhoto.endsWith("featured-photo-placeholder.png");

  const sizes = getMediaSizes([
    { breakpoint: "md", size: "33vw" },
    { breakpoint: "lg", size: "25vw" },
    { size: "12.5vw" },
  ]);

  const content = (
    <div className="relative">
      {withLikeButton && (
        <LikeButton
          className="w-6 absolute right-2 top-2 text-black z-10"
          {...{ productId }}
        />
      )}
      <div
        className="aspect-w-8 aspect-h-10"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Transition
          show={!hover || !hasHighlightPhoto}
          appear
          enter="transition-all ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-all ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Image
            src={photo}
            alt={name}
            loader={imageLoader}
            layout="fill"
            objectFit="cover"
            sizes={sizes}
            className={classNames(
              "transition-all duration-200",
              !hasHighlightPhoto ? "hover:scale-110" : ""
            )}
          />
        </Transition>
        <Transition
          show={hover && hasHighlightPhoto}
          appear
          enter="transition-all ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-all ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Image
            src={highlightPhoto}
            alt={name}
            loader={imageLoader}
            layout="fill"
            objectFit="cover"
            sizes={sizes}
            className="hover:scale-110 duration-100 transition-all"
          />
        </Transition>
      </div>
      {onDiscount && (
        <div className="bg-red-600 text-white text-sm leading-none p-1 rounded-r-md absolute bottom-2">
          {t("discount")}
        </div>
      )}
    </div>
  );

  return withLink ? (
    <Link href={{ pathname: "/products/[slug]", query: { slug } }}>
      <a>{content}</a>
    </Link>
  ) : (
    content
  );
};

export default ProductImage;
