import { FC, useState } from "react";
import HeartIcon from "@heroicons/react/outline/HeartIcon";
import ChatIcon from "@heroicons/react/outline/ChatIcon";
import { useTranslation } from "next-i18next";

import PostsCarousel from "./posts-carousel";

import { MediaObject } from "@interfaces/instagram";
import { classNames } from "@utils/class-names";
import { useStaticData } from "@context/static";

const Post: FC<MediaObject & { onClick(): void }> = (props) => {
  const { t } = useTranslation("home");
  return (
    <div
      className="aspect-w-1 aspect-h-1 w-full relative group"
      onClick={props.onClick}
    >
      <img
        src={
          props.media_type === "VIDEO" && props.thumbnail_url
            ? props.thumbnail_url
            : props.media_url
        }
        alt={props.caption}
        className="object-cover"
      />
      <div className="hidden group-hover:flex absolute inset-0 items-center justify-center bg-black/30">
        <div className="h-10 w-2/3 flex items-center justify-center space-x-1">
          <HeartIcon className="text-black h-2/3" />
          <div className="text-lg h-full flex items-center justify-center">
            {props.like_count}
          </div>
          <ChatIcon className="text-black h-2/3 scale-x-[-1]" />
          <div className="text-lg h-full flex items-center justify-center">
            {props.comments_count}
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 h-1/4 bg-black text-white flex items-center justify-center">
          {t("featured.posts.view")}
        </div>
      </div>
    </div>
  );
};

interface Props {
  posts: MediaObject[];
}

const FeaturedPosts: FC<Props> = ({ posts }) => {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(-1);
  const { socialLinks } = useStaticData();
  const { t } = useTranslation("home");
  const instagram = socialLinks.find(
    ({ name }) => name.toLowerCase() === "instagram"
  );

  return (
    <div>
      <div className="flex flex-col justify-center items-center relative my-10">
        <p>{t("featured.posts.share")}</p>
        <h3 className="text-3xl font-bold pt-2">#Renya</h3>
        <div className="pt-2 md:absolute md:top-0 md:right-0 md:pr-10 flex flex-col justify-center items-center">
          {t("featured.posts.follow")}{" "}
          <a
            href={instagram?.link}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer font-semibold hover:underline block"
          >
            @
            {instagram?.link.slice(
              instagram?.link.slice(0, -1).lastIndexOf("/") + 1,
              -1
            )}
          </a>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 px-10 mb-10">
        {posts.map((post, index) => (
          <div
            key={post.id}
            className={classNames(
              index === 4 ? "col-span-2 row-span-2" : "col-span-1 row-span-1"
            )}
          >
            <Post
              {...post}
              onClick={() => {
                setSelected(index);
                setShow(true);
              }}
            />
          </div>
        ))}
      </div>
      <PostsCarousel
        {...{ initial: selected, posts, show, hide: () => setShow(false) }}
      />
    </div>
  );
};

export default FeaturedPosts;
