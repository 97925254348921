import { FC } from "react";
import Slider, { CustomArrowProps, Settings } from "react-slick";
import ChevronLeftIcon from "@heroicons/react/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/outline/ChevronRightIcon";

import { useSliderResize } from "@hooks/use-slider-resize";

const PrevArrow: FC<CustomArrowProps> = ({ className, style, onClick }) => (
  <button
    onClick={onClick}
    className={className}
    style={style}
    aria-label="prev"
  >
    <ChevronLeftIcon className="w-8" />
  </button>
);

const NextArrow: FC<CustomArrowProps> = ({ className, style, onClick }) => (
  <button
    onClick={onClick}
    className={className}
    style={style}
    aria-label="next"
  >
    <ChevronRightIcon className="w-8" />
  </button>
);

interface Props {
  breakpoints: {
    xxl?: number;
    xl?: number;
    lg?: number;
    md?: number;
    sm?: number;
    base: number;
  };
  settings?: Omit<Settings, "slidesToShow">;
}

const ResponsiveSlider: FC<Props> = ({ children, breakpoints, settings }) => {
  const slidesToShow = useSliderResize(breakpoints);

  const defaultSettings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const className = `px-6 responsive-slider ${settings?.className ?? ""}`;

  return (
    <Slider {...{ ...defaultSettings, ...settings, className, slidesToShow }}>
      {children}
    </Slider>
  );
};

export default ResponsiveSlider;
