import { FC } from "react";
import Link from "next/link";
import Image from "next/image";

import { classNames } from "@utils/class-names";
import { getMediaSizes, imageLoader } from "@utils/get-media";

interface Props {
  title: string;
  href: string;
  img: string;
  outside?: boolean;
}

const ImageLink: FC<Props> = ({ title, href, img, outside }) => {
  const attributes = outside ? { href, target: "_blank" } : {};
  const image = (
    <a
      className={classNames(
        "group block relative w-full",
        outside ? "md:w-2/3 h-full overflow-hidden" : "w-1/2 lg:w-1/4"
      )}
      {...attributes}
    >
      <div className="absolute w-full h-full bg-transparent group-hover:bg-white/40 transition duration-200 z-20" />
      <div className="absolute w-full h-1/3 flex justify-center items-center">
        <h3 className="text-lg font-semibold text-black px-4 py-2 rounded-md group-hover:bg-transparent transition duration-200 z-20 uppercase bg-white/40">
          {title}
        </h3>
      </div>
      <div className="aspect-w-10 aspect-h-13">
        <Image
          src={img}
          alt={title}
          layout="fill"
          objectFit="cover"
          loader={imageLoader}
          sizes={getMediaSizes([
            { breakpoint: "md", size: "100vw" },
            { breakpoint: "lg", size: "50vw" },
            { size: "25vw" },
          ])}
        />
      </div>
    </a>
  );

  return <>{outside ? image : <Link {...{ href }}>{image}</Link>}</>;
};

export default ImageLink;
