import { FC } from "react";
import Link from "next/link";
import { Product } from "@prisma/client";
import { useRouter } from "next/router";

import ProductImage from "@components/common/product-image";
import { calculatePrice, formatPrice } from "@utils/price";

type Props = Pick<
  Product,
  | "id"
  | "slug"
  | "cardName"
  | "cardNameEn"
  | "price"
  | "discount"
  | "description"
  | "descriptionEn"
  | "featuredPhoto"
  | "featuredPhotoHighlight"
>;

const ProductCard: FC<Props> = ({
  id,
  cardName,
  cardNameEn,
  slug,
  price,
  discount,
  description,
  descriptionEn,
  featuredPhoto,
  featuredPhotoHighlight,
}) => {
  const { locale } = useRouter();

  return (
    <Link
      href={{
        pathname: "/products/[slug]",
        query: { slug },
      }}
    >
      <a className="block">
        <ProductImage
          {...{
            photo: featuredPhoto,
            highlightPhoto: featuredPhotoHighlight,
            onDiscount: discount !== 0,
            productId: id,
            withLink: false,
            name: locale === "en" ? cardNameEn : cardName,
          }}
        />
        <div className="sm:px-1 space-y-2 text-black">
          <div className="font-semibold flex justify-between mt-4 text-sm 2xl:text-base">
            <h3>{locale === "en" ? cardNameEn : cardName}</h3>
            <div className="relative">
              {discount !== 0 && (
                <div className="absolute -top-3 -right-1 text-red-600">
                  {formatPrice(calculatePrice(price, discount), true)}
                </div>
              )}
              <div
                {...{
                  className:
                    discount !== 0
                      ? "line-through font-normal text-sm"
                      : undefined,
                }}
              >
                {formatPrice(price, true)}
              </div>
            </div>
          </div>
          <div className="h-9">
            <p className="text-xs md:text-sm line-clamp-2">
              {locale === "en" ? descriptionEn : description}
            </p>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default ProductCard;
