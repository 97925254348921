import { FC } from "react";
import Slider, { CustomArrowProps, Settings } from "react-slick";
import ChevronLeftIcon from "@heroicons/react/solid/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/solid/ChevronRightIcon";
import { LandingPageSlide } from "@prisma/client";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { getMedia, imageLoader } from "@utils/get-media";

const PrevArrow: FC<CustomArrowProps> = ({ className, style, onClick }) => {
  return (
    <button
      className={`${className} rounded-md`}
      style={{
        ...style,
        color: "white",
        height: "75px",
        width: "50px",
        zIndex: 10,
      }}
      onClick={onClick}
      aria-label="prev"
    >
      <ChevronLeftIcon className="w-12" />
    </button>
  );
};

const NextArrow: FC<CustomArrowProps> = ({ className, style, onClick }) => {
  return (
    <button
      className={`${className} rounded-md`}
      style={{
        ...style,
        color: "white",
        height: "75px",
        width: "50px",
      }}
      onClick={onClick}
      aria-label="next"
    >
      <ChevronRightIcon className="w-12" />
    </button>
  );
};

const settings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 4_000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  className: "home-slideshow",
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  // eslint-disable-next-line react/display-name
  appendDots: (dots) => (
    <div>
      <div className="flex items-center justify-center">
        <ul className="bg-black bg-opacity-60 flex rounded-md">{dots}</ul>
      </div>
    </div>
  ),
  // eslint-disable-next-line react/display-name
  customPaging: (i) => (
    <div className="flex justify-center items-center w-full h-full">
      <button aria-label={`slide ${i}`} />
    </div>
  ),
};

interface Props {
  slides: LandingPageSlide[];
}

const Slideshow: FC<Props> = ({ slides }) => {
  const { locale } = useRouter();
  const { t } = useTranslation("home");

  return slides.length > 0 ? (
    <Slider {...settings}>
      {slides.map(({ id, text, isPhoto, featuredMedia, textEn, url }) => (
        <div key={id} className="relative h-full aspect-w-12 aspect-h-5">
          {text && text.length > 0 && (
            <div className="absolute w-3/4 h-3/4 top-[12.5%] left-[12.5%] px-1 md:px-4 md:w-1/2 md:h-1/2 md:top-1/4 md:left-1/4 flex flex-col justify-center items-center space-y-10 z-10">
              <h1 className="text-4xl font-bold text-white text-center tracking-widest">
                {locale === "en" ? textEn : text}
              </h1>
              {url && url.length > 0 && (
                <a
                  href={url}
                  className="text-xl font-semibold underline tracking-wide block text-white text-center"
                >
                  {t("discover")}
                </a>
              )}
            </div>
          )}
          {isPhoto ? (
            <Image
              src={featuredMedia}
              alt=""
              layout="fill"
              loader={imageLoader}
              objectFit="cover"
            />
          ) : (
            <video
              autoPlay
              loop
              muted
              className="h-full max-h-full max-w-screen object-cover w-full"
            >
              <source
                src={getMedia(featuredMedia)}
                type={`video/${featuredMedia.slice(
                  featuredMedia.lastIndexOf(".") + 1
                )}`}
              />
            </video>
          )}
        </div>
      ))}
    </Slider>
  ) : null;
};

export default Slideshow;
