import { FC } from "react";
import { Product } from "@prisma/client";
import { useTranslation } from "next-i18next";

import ProductCard from "@components/common/product-card";
import ResponsiveSlider from "@components/common/responsive-slider";

interface Props {
  products: Product[];
}

const FeaturedProducts: FC<Props> = ({ products }) => {
  const { t } = useTranslation("home");

  return products.length > 0 ? (
    <div className="max-w-screen flex flex-col items-center py-10">
      <h2 className="text-black text-2xl font-bold pb-4">
        {t("featured.products")}
      </h2>
      <div className="w-full max-h-[min-content] mb-4">
        <ResponsiveSlider
          breakpoints={{
            xl: 8,
            lg: 6,
            md: 4,
            sm: 3,
            base: 2,
          }}
          settings={{
            className: "products-slider",
          }}
        >
          {products.map((product) => (
            <div key={product.id} className="w-1/3 md:w-1/4 lg:w-[12.5%] px-3">
              <ProductCard {...product} />
            </div>
          ))}
        </ResponsiveSlider>
      </div>
    </div>
  ) : null;
};

export default FeaturedProducts;
